import React from 'react';
import styled, { keyframes } from 'styled-components';

const TEXT_COLOR = '#d1d1e4';
const BACKGROUND_COLOR = '#1c1c22';

const sunrise = keyframes`
  from {
    transform: translateY(32px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Sunrays = styled.path`
  animation-name: ${sunrise};
  animation-duration: 600ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
`;

export default function Logo({
  size,
  color = TEXT_COLOR,
  backgroundColor = BACKGROUND_COLOR,
  ...otherProps
}) {
  return (
    <svg
      style={{ isolation: 'isolate' }}
      viewBox="778 434.5 314 310"
      width={size || 150}
      height={size || 150}
      color={color}
      {...otherProps}
    >
      <defs>
        <clipPath id="logoA">
          <path d="M778 434.5h314v310H778z" />
        </clipPath>
      </defs>
      <g clipPath="url(#logoA)">
        <path fill={backgroundColor} d="M778 434.5h314v310H778z" />
        <Sunrays
          fill={color}
          d="M992.398 546.312l44.541-44.54 9.721 9.72-44.54 44.542zM834.946 501.572l44.54 44.54-9.72 9.722-44.541-44.541zM941.875 483.263v63h-13.75v-63z"
        />
        <path
          d="M1006.42 569.37h-13.86q-3.06-16.56-24.3-16.56-11.34 0-17.46 4.95-6.12 4.95-6.12 11.88 0 6.93 3.78 10.53 3.78 3.6 12.24 6.3l23.58 8.1q12.06 4.14 19.17 11.34 7.11 7.2 7.11 20.25t-11.16 21.96q-11.16 8.91-29.88 8.91t-30.69-9.45q-11.97-9.45-12.33-26.55h13.86q0 11.16 7.83 17.1 7.83 5.94 20.16 5.94t19.53-5.49q7.2-5.49 7.2-12.96 0-7.47-4.41-11.79-4.41-4.32-13.05-7.2l-19.98-7.38q-14.04-4.68-21.24-11.16-7.2-6.48-7.2-18.99 0-12.51 10.08-20.88t28.26-8.37q18.18 0 27.81 8.01 9.63 8.01 11.07 21.51z"
          fill={backgroundColor}
        />
        <path
          d="M941.265 569.37h-13.86q-3.06-16.56-24.3-16.56-11.34 0-17.46 4.95-6.12 4.95-6.12 11.88 0 6.93 3.78 10.53 3.78 3.6 12.24 6.3l23.58 8.1q12.06 4.14 19.17 11.34 7.11 7.2 7.11 20.25t-11.16 21.96q-11.16 8.91-29.88 8.91t-30.69-9.45q-11.97-9.45-12.33-26.55h13.86q0 11.16 7.83 17.1 7.83 5.94 20.16 5.94t19.53-5.49q7.2-5.49 7.2-12.96 0-7.47-4.41-11.79-4.41-4.32-13.05-7.2l-19.98-7.38q-14.04-4.68-21.24-11.16-7.2-6.48-7.2-18.99 0-12.51 10.08-20.88t28.26-8.37q18.18 0 27.81 8.01 9.63 8.01 11.07 21.51z"
          fill={backgroundColor}
        />
        <path
          d="M970.515 570.78h-13.86q-3.06-16.56-24.3-16.56-11.34 0-17.46 4.95-6.12 4.95-6.12 11.88 0 6.93 3.78 10.53 3.78 3.6 12.24 6.3l23.58 8.1q12.06 4.14 19.17 11.34 7.11 7.2 7.11 20.25t-11.16 21.96q-11.16 8.91-29.88 8.91t-30.69-9.45q-11.97-9.45-12.33-26.55h13.86q0 11.16 7.83 17.1 7.83 5.94 20.16 5.94t19.53-5.49q7.2-5.49 7.2-12.96 0-7.47-4.41-11.79-4.41-4.32-13.05-7.2l-19.98-7.38q-14.04-4.68-21.24-11.16-7.2-6.48-7.2-18.99 0-12.51 10.08-20.88t28.26-8.37q18.18 0 27.81 8.01 9.63 8.01 11.07 21.51z"
          fill={backgroundColor}
        />
        <path
          d="M972.89 609.06h-13.86q-3.06-16.56-24.3-16.56-11.34 0-17.46 4.95-6.12 4.95-6.12 11.88 0 6.93 3.78 10.53 3.78 3.6 12.24 6.3l23.58 8.1q12.06 4.14 19.17 11.34 7.11 7.2 7.11 20.25t-11.16 21.96q-11.16 8.91-29.88 8.91t-30.69-9.45q-11.97-9.45-12.33-26.55h13.86q0 11.16 7.83 17.1 7.83 5.94 20.16 5.94t19.53-5.49q7.2-5.49 7.2-12.96 0-7.47-4.41-11.79-4.41-4.32-13.05-7.2l-19.98-7.38q-14.04-4.68-21.24-11.16-7.2-6.48-7.2-18.99 0-12.51 10.08-20.88t28.26-8.37q18.18 0 27.81 8.01 9.63 8.01 11.07 21.51z"
          fill={color}
        />
      </g>
    </svg>
  );
}
